export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMAT_START_END = 'DD/MM/YYYY';

export const DATE_FORMAT_API = 'YYYY-MM-DD';

export const TIME_FORMAT = 'HH:mm';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';

export const logo = `${process.env.PUBLIC_URL}/logo.png`;

export const defaultPagePath = '/students';

export const DEBOUNCE_DELAY = 400;

export const GenderTypes = ['MALE', 'FEMALE', 'OTHER'];

export const RoleTypes = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  USER: 'USER',
  ADMIN: 'ADMIN',
  CONSULTANT: 'CONSULTANT',
  ACADEMIC_AFFAIR: 'ACADEMIC_AFFAIR',
  REGISTRAR: 'REGISTRAR',
  INSTRUCTOR: 'INSTRUCTOR',
  STUDENT: 'STUDENT'
};

export const CurrencyTypes = {
  USD: 'USD',
  VND: 'VND'
};

export const DayOfWeekTypes = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday'
};
export const SORT_MAPPING_API_TO_TABLE = {
  ASC: 'ascend',
  DESC: 'descend'
};
