import { handleActions } from 'redux-actions';

const initialState = {
  secretKey: '',
  filterExceptColumns: {},
  sheetData: {
    requesting: false,
    result: {},
    error: null
  },
  sheetDataPost: {
    requesting: false,
    result: [],
    error: null
  },
  setting: {
    requesting: false,
    result: [],
    error: null
  }
};

export const Common = handleActions(
  {
    SET_SECRET_KEY: (state, { payload }) => ({
      ...state,
      secretKey: payload
    }),
    SET_FILTER_EXCEPT_COLUMNS: (state, { payload }) => ({
      ...state,
      filterExceptColumns: payload
    }),
    GET_SHEET_REQUEST: (state) => ({
      ...state,
      sheetData: {
        ...state.sheetData,
        requesting: true
      }
    }),
    GET_SHEET_SUCCESS: (state, { payload }) => ({
      ...state,
      sheetData: {
        ...state.sheetData,
        requesting: false,
        result: payload.data,
        error: null
      }
    }),
    GET_SHEET_FAIL: (state, { payload }) => ({
      ...state,
      sheetData: {
        ...state.sheetData,
        requesting: false,
        error: payload.error
      }
    }),
    POST_SHEET_REQUEST: (state) => ({
      ...state,
      sheetDataPost: {
        ...state.sheetDataPost,
        requesting: true
      }
    }),
    POST_SHEET_SUCCESS: (state, { payload }) => ({
      ...state,
      sheetDataPost: {
        ...state.sheetDataPost,
        requesting: false,
        result: payload.data,
        error: null
      }
    }),
    POST_SHEET_FAIL: (state, { payload }) => ({
      ...state,
      sheetDataPost: {
        ...state.sheetDataPost,
        requesting: false,
        error: payload.error
      }
    }),
    GET_SETTING_REQUEST: (state) => ({
      ...state,
      setting: {
        ...state.setting,
        requesting: true
      }
    }),
    GET_SETTING_SUCCESS: (state, { payload }) => ({
      ...state,
      setting: {
        ...state.setting,
        requesting: false,
        result: payload.data,
        error: null
      }
    }),
    GET_SETTING_FAIL: (state, { payload }) => ({
      ...state,
      setting: {
        ...state.setting,
        requesting: false,
        error: payload.error
      }
    }),
  },
  initialState
);

export default Common;
