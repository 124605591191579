import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import commonReducer from './modules/Common/reducer';
import omsReducer from './modules/Oms/reducer';

const commonPersistConfig = {
  key: 'common',
  storage: localForage,
  whitelist: ['secretKey', 'filterExceptColumns', 'setting'],
};

const omsPersistConfig = {
  key: 'oms',
  storage: localForage,
  whitelist: ['setting'],
};

export default {
  common: persistReducer(commonPersistConfig, commonReducer),
  oms: persistReducer(omsPersistConfig, omsReducer),
};
