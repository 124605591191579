import { Spin } from 'antd';
import React from 'react';

export default function LoadingFullContainer() {
  return (
    <div
      style={{
        textAlign: 'center',
        marginBottom: '20px',
        padding: '30px 50px',
        margin: '20px 0'
      }}
    >
      <Spin tip="Loading..." size="large">
        <div className="content" />
      </Spin>
    </div>
  );
}
