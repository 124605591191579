import 'antd/dist/reset.css';
import './assets/styles/_variables.scss';
import './assets/styles/main.scss';
import './assets/styles/responsive.scss';
import './setupMoment';

import { DevSupport } from '@react-buddy/ide-toolbox';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import LoadingFullContainer from './components/LoadingFullContainer';
import { ComponentPreviews, useInitial } from './dev';
import DefaultRoutes from './navigator';
import configureStore from './store/configureStore';

const container = document.getElementById('root');
const root = createRoot(container);

const initialState = {};
const { store, persistor } = configureStore(initialState);

root.render(
  <BrowserRouter>
    <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
      <PersistGate
        persistor={persistor}
        loading={
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <LoadingFullContainer />
          </div>
        }
      >
        <Provider store={store}>
          <DefaultRoutes />
        </Provider>
      </PersistGate>
    </DevSupport>
  </BrowserRouter>
);
