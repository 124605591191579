import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export default function HeaderPrevNext({
  pos,
  currentPage,
  total,
  limit,
  onPrev,
  onNext,
  }) {
  return (
    <Row gutter={[24, 0]} justify="center" align="middle">
      <Col span={16}>
        <p style={{ textAlign: 'center', fontSize: 16, margin: 0 }}>{`${
          (currentPage - 1) * limit + pos + 1
        } / ${total}`}</p>
      </Col>
      <Col span={8}>
        <Row gutter={[8, 0]} justify="end">
          <Button
            onClick={onPrev}
            type="ghost"
            style={{ textAlign: 'left', color: '#1890ff' }}
          >
            <strong>
              <LeftOutlined /> Prev
            </strong>
          </Button>
          <Button
            onClick={onNext}
            type="ghost"
            style={{ textAlign: 'right', color: '#1890ff' }}
          >
            <strong>
              Next <RightOutlined />
            </strong>
          </Button>
        </Row>

      </Col>
    </Row>
  );
}

HeaderPrevNext.propTypes = {
  pos: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
}
