import { Button, Modal, Row } from 'antd';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';

import CustomForm from '../../../../components/CustomForm';
import CheckboxGroupValidation from '../../../../components/Fields/CheckboxGroupValidation';
import DatePickerValidation from '../../../../components/Fields/DatePickerValidation';
import FieldValidation from '../../../../components/Fields/FieldValidation';
import RadioValidation from '../../../../components/Fields/RadioValidation';
import HeaderPrevNext from '../../../../components/HeaderPrevNext';
import { parseTextJoinForSheet } from '../../../../utils/common.util';

const IndexForm = ({ title, handleSubmit, visible, onCancel, requesting, showPrevNext, values }) => {
  const ref = useRef();

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      ref.current.submit();
    }
  };

  const defaultFields = [
    {
      name: '1',
      label: 'Name',
      component: FieldValidation
    },
    {
      name: '2',
      label: 'Phone',
      component: FieldValidation
    },
    {
      name: '3',
      label: 'Email',
      component: FieldValidation
    }
  ];

  const [fields, setFields] = React.useState(defaultFields);

  const setting = useSelector((state) => state.common.setting.result) ?? [];
  const headers = useSelector((state) => state.common.sheetData.result?.headers) ?? [];
  const answerIndex = headers.findIndex((item) => item === 'Email') + 1;

  useEffect(() => {
    if (setting.length === 0) return;
    const newFields = [];
    for (let i = 0; i < setting.length; i++) {
      if (setting[i].type === 'Radio') {
        newFields.push({
          name: `${i + answerIndex}`,
          label: setting[i]?.question,
          component: RadioValidation,
          options: setting[i]?.options,
          labelName: 'value',
          valueName: 'row',
        });
      } else if (setting[i].type === 'Text' || setting[i].type === 'Textarea') {
        newFields.push({
          name: `${i + answerIndex}`,
          label: setting[i].question,
          component: FieldValidation,
          textArea: setting[i].type === 'Textarea',
        });
      } else if (setting[i].type === 'Checkbox') {
        newFields.push({
          name: `${i + answerIndex}`,
          label: setting[i].question,
          component: CheckboxGroupValidation,
          options: setting[i]?.options,
          labelName: 'value',
          valueName: 'row',
        });
      } else if (setting[i].type === 'Datetime') {
        newFields.push({
          name: `${i + answerIndex}`,
          label: setting[i].question,
          component: DatePickerValidation,
          outFormat: 'MM-DD-YYYY',
        });
      }
    }
    setFields([...defaultFields, ...newFields]);
  }, [setting, headers]);

  return (
    <Modal
      width={800}
      maskClosable={false}
      title={title}
      open={visible}
      onCancel={onCancel}
      footer={
        <Row justify="end">
          <Button type="primary" onClick={handleSubmit} loading={requesting} disabled={requesting}>
            {requesting ? 'Submitting...' : 'Submit'}
          </Button>
        </Row>
      }
    >
      {showPrevNext && <HeaderPrevNext {...showPrevNext} />}
      <CustomForm fields={fields} handleKeyUp={handleKeyUp} onSubmit={handleSubmit} />
    </Modal>
  );
};

IndexForm.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export const validationSchema = Yup.object().shape({});

export default compose(
  connect((state) => ({
    setting: state.common.setting.result
  })),
  withFormik({
    validateOnChange: false,
    validationSchema,
    mapPropsToValues: (props) => {
      const { initialValues, setting } = props;
      const initValues = initialValues ?? {};
      const data = {};

      for (const key in initValues) {
        if (Object.hasOwnProperty.call(initValues, key)) {
          if (typeof initValues[key] === 'string' && initValues[key]?.startsWith('=')) {
            const type = setting[key - 4]?.type;
            if (type === 'Checkbox') {
              data[key] = parseTextJoinForSheet(initValues[key]);
            } else {
              data[key] = initValues[key].replace(/=/g, '');
            }
          } else {
            data[key] = initValues[key];
          }
        }
      }

      return {
        ...data
      };
    },
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      const { onSubmit } = props;
      setSubmitting(false);
      const { ...rest } = values;

      onSubmit(
        {
          ...rest
        },
        resetForm
      );
    }
  })
)(IndexForm);
