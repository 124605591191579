import { message } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postSheet } from '../../../../store/modules/Common/actions';
import { getErrorMessage } from '../../../../utils/common.util';
import ModalForm from '../Form/index.form';

export default function EditModal({ visible, onCancel, initialValues, onRefresh, showPrevNext }) {
  const dispatch = useDispatch();

  const secretKey = useSelector((state) => state.common.secretKey);

  const onSubmit = useCallback(
    (values, resetForm) => {
      const { id, ...rest } = values;

      const bodyValues = Object.values(rest).map((value) => {
        if (typeof value === 'string' && value.startsWith('Setting!')) {
          return `=${value}`;
        }
        if (Array.isArray(value)) {
          return `=TEXTJOIN(", ", TRUE, ${value.join(',')})`;
        }
        return value;
      });

      const body = {
        id,
        values: bodyValues,
        secret: secretKey
      };

      dispatch(postSheet(body)).then((res) => {
        const { type } = res;
        if (type === 'POST_SHEET_FAIL') {
          const mess = getErrorMessage(res?.payload?.error) ?? 'Update Failed';
          return message.error(mess);
        }
        onRefresh();
        return message.success('Update Successfully');
      });
    },
    [dispatch, onCancel, onRefresh, secretKey]
  );

  const requesting = useSelector((state) => state.common.sheetDataPost?.requesting);

  return visible ? (
    <ModalForm
      title="Edit"
      visible={visible}
      onCancel={onCancel}
      onSubmit={onSubmit}
      requesting={requesting}
      initialValues={initialValues}
      isEdit
      showPrevNext={showPrevNext}
    />
  ) : null;
}
