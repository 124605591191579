import { DeleteFilled, EditFilled, EyeFilled } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React from 'react';

import styles from './styles.module.scss';

const TableAction = ({ item, onEdit, onDelete, onView, deleteRequesting }) => {
  return (
    <div className={styles.wrapper}>
      {onView && (
        <Button type="primary" onClick={() => onView(item)}>
          <EyeFilled />
        </Button>
      )}
      {onEdit && (
        <Button className={styles.editButton} onClick={() => onEdit(item)}>
          <EditFilled style={{ color: 'white' }} />
        </Button>
      )}
      {onDelete && (
        <Popconfirm
          title="Are you sure you want to delete it?"
          onConfirm={() => onDelete(item.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            className={styles.deleteButton}
            loading={deleteRequesting}
            disabled={deleteRequesting}
          >
            <DeleteFilled style={{ color: 'white' }} />
          </Button>
        </Popconfirm>
      )}
    </div>
  );
};
export default TableAction;
