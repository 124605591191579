import { Form } from 'antd';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { defaultFormItemLayout } from '../../utils/common.util';

export default function CustomForm({ fields, onSubmit, ref, handleKeyUp }) {
  const defaultChildFormItemLayout = {
    style: {
      marginBottom: 0,
      display: 'inline-block'
    }
  };

  return (
    <Form
      className="row-col"
      onSubmit={onSubmit}
      ref={ref}
      onKeyUp={handleKeyUp}
      style={{ marginTop: 20 }}
      {...defaultFormItemLayout}
    >
      {fields
        .filter((item) => !item.isHide)
        .map((field, index) =>
          field?.isSameRow ? (
            <Form.Item
              key={index}
              label={field.label}
              required={field.required}
              style={{ marginBottom: 0 }}
            >
              <div style={{ display: 'flex', gap: 10 }}>
                {field.children.map((child, childKey) =>
                  child?.fieldType === 'object' ? (
                    child.items.map(({ name, ...rest }, index) => (
                      <Field
                        key={`${name}-${index}`}
                        {...rest}
                        name={`${child.name}.${name}`}
                        formItemLayout={{
                          ...(child.formItemLayout ?? {}),
                          style: {
                            ...defaultChildFormItemLayout.style,
                            ...child.formItemLayout?.style
                          }
                        }}
                      />
                    ))
                  ) : (
                    <Field
                      key={childKey}
                      {...child}
                      formItemLayout={{
                        ...(child.formItemLayout ?? {}),
                        style: {
                          ...defaultChildFormItemLayout.style,
                          ...child.formItemLayout?.style
                        }
                      }}
                    />
                  )
                )}
              </div>
            </Form.Item>
          ) : field?.fieldType === 'object' ? (
            field.items.map(({ name, ...rest }, index) => (
              <Field key={`${name}-${index}`} {...rest} name={`${field.name}.${name}`} />
            ))
          ) : (
            <Field key={index} {...field} />
          )
        )}
    </Form>
  );
}

CustomForm.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ref: PropTypes.object,
  handleKeyUp: PropTypes.func
};
