import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox,Col, Modal, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleFilterExceptColumns } from '../../store/modules/Common/actions';

function FlexTable({
  title,
  extraComponent,
  columns,
  data,
  rowKey,
  requesting,
  params,
  totalItems,
  onChangeTable,
  header,
  footer,
  pagination,
  keyFilterColumn,
  // onChangeFilterColumn,
  ...rest
}) {
  const dispatch = useDispatch();

  const filterExceptColumns = useSelector((state) => state.common.filterExceptColumns);

  const exceptColumns = filterExceptColumns[keyFilterColumn] || [];

  const [visibleColumns, setVisibleColumns] = React.useState(false);
  const allColumns = columns.map((col) => {
    return {
      value: col.key,
      label: col.title,
    };
  });

  const checked = columns.filter((col) => !exceptColumns.includes(col.key)).map((col) => col.key);

  const [checkedList, setCheckedList] = React.useState(checked);

  const onChangeFilterColumn = (checkedList) => {
    setCheckedList(checkedList);
  }

  const onOk = useCallback(() => {
    const exceptColumns = columns.map((col) => col.key).filter((col) => !checkedList.includes(col));
    dispatch(handleFilterExceptColumns(keyFilterColumn, exceptColumns));
    setVisibleColumns(false);
  }, [keyFilterColumn, columns, checkedList]);

  let instanceColumns = [...columns];

  if (keyFilterColumn) {
    instanceColumns = columns.filter((col) => !exceptColumns.includes(col.key));
  }

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

  // change isMobile when resize listener
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="table">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 width-100"
            title={title}
            extra={extraComponent}
          >
            {header}
            <div className="table-responsive">
              {keyFilterColumn && (
                <Row gutter={[16, 0]} justify="end" style={{ margin: '0 20px 20px 0' }}>
                  <Button
                    type="dashed"
                    onClick={() => setVisibleColumns(!visibleColumns)}
                  >
                    <EyeOutlined /> View Columns
                  </Button>
                </Row>
              )}
              <Table
                columns={instanceColumns}
                dataSource={data}
                className="ant-border-space"
                rowKey={rowKey}
                loading={requesting}
                bordered
                pagination={{
                  pageSizeOptions: ['10', '20', '50'],
                  defaultCurrent: 1,
                  current: params.page,
                  total: totalItems,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  pageSize: params.limit,
                  showLessItems: true,
                  simple: isMobile,
                  ...pagination
                }}
                onChange={onChangeTable}
                {...rest}
              />
              {footer}
            </div>
          </Card>
        </Col>
      </Row>
      {
        keyFilterColumn && visibleColumns && (
          <Modal
            title="View Columns"
            open={visibleColumns}
            onOk={onOk}
            onCancel={() => setVisibleColumns(false)}
          >
            <Checkbox.Group
              options={allColumns}
              defaultValue={checked}
              onChange={onChangeFilterColumn}
            />
          </Modal>
        )
      }

    </div>
  );
}

FlexTable.propTypes = {
  extraComponent: PropTypes.element,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
  requesting: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  totalItems: PropTypes.number.isRequired,
  onChangeTable: PropTypes.func.isRequired
};

FlexTable.defaultProps = {
  extraComponent: null,
  rowKey: 'id',
  requesting: false,
  params: {
    page: 1,
    limit: 10
  },
  totalItems: 0
};

export default FlexTable;
