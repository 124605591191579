import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { Component } from 'react';

import { DATE_FORMAT } from '../../../constants';
import { FloatContainer } from '../float.style';

const { RangePicker } = DatePicker;

class DatePickerValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floatFocus: false
    };
  }

  componentDidMount() {
    const { field, form, outFormat } = this.props;
    const { setFieldValue } = form;

    if (field.value) {
      setFieldValue(field.name, dayjs(field.value));
    }
  }

  getTimeValue = (value) => {
    const { outFormat } = this.props;
    if (outFormat) {
      return value instanceof dayjs
        ? value.format(outFormat)
        : dayjs(value, outFormat || DATE_FORMAT).format(outFormat);
    }
    return dayjs(value).format();
  };

  onChange = (date) => {
    const { form, field } = this.props;
    const { setFieldValue } = form;

    let value;

    if (Array.isArray(date)) {
      value = date || null;
    } else {
      value = date ? this.getTimeValue(date) : null;
    }

    setFieldValue(field.name, value);
  };

  render() {
    const {
      id,
      required,
      label,
      field,
      placeholder,
      form,
      containerClassName,
      datepickerSize,
      formItemLayout,
      disabled,
      fieldProps,
      showTime,
      isRange,
      outFormat,
      useFloat
    } = this.props;

    const { floatFocus } = this.state;

    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';

    let value = field.value ? field.value : null;
    if (typeof value === 'string' || typeof value === 'number') {
      value = dayjs(value);
    }

    const DateComponent = isRange ? RangePicker : DatePicker;

    const InstanceComponent = (
      <DateComponent
        style={{ width: '100%' }}
        name={field.name}
        value={value || null}
        format={outFormat || DATE_FORMAT}
        onChange={this.onChange}
        size={datepickerSize || 'default'}
        placeholder={useFloat ? '' : placeholder}
        defaultPickerValue={dayjs()}
        disabled={disabled}
        showTime={showTime}
        useUTC
        // disabledDate={(current) => current && current <= dayjs().subtract(1, 'd').endOf('day')}
        {...fieldProps}
      />
    );

    const isOccupied = floatFocus || value;

    const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

    const requiredMark = required ? <span className="text-danger">*</span> : null;

    const FloatComponent = (
      <FloatContainer>
        <div
          className="float-label"
          onBlur={() => this.setState({ floatFocus: false })}
          onFocus={() => this.setState({ floatFocus: true })}
        >
          {InstanceComponent}
          <label className={labelClass} htmlFor={field.name}>
            {requiredMark}
            {isOccupied ? label : placeholder}
          </label>
        </div>
      </FloatContainer>
    );

    return (
      <Form.Item
        required={required}
        id={id}
        label={useFloat ? '' : label}
        className={containerClassName}
        validateStatus={validateStatus}
        help={fieldError}
        {...formItemLayout}
      >
        {useFloat ? FloatComponent : InstanceComponent}
      </Form.Item>
    );
  }
}

export default DatePickerValidation;
