import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getSetting } from '../store/modules/Common/actions';
import { getOmsSetting } from '../store/modules/Oms/actions';

export function useAppInit() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSetting({
        action: 'settings'
      })
    );
    dispatch(getOmsSetting());
  }, [dispatch]);
}
