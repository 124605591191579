import { Route, Switch } from 'react-router-dom';

import MainLayout from './containers/Layout/main';
import HomePage from './containers/Pages/Home';
import OmsPage from './containers/Pages/Oms';
import { useAppInit } from './hooks/useAppInit';

function App() {
  useAppInit();

  return (
    <MainLayout>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/oms" exact component={OmsPage} />
      </Switch>
    </MainLayout>
  );
}

export default App;
