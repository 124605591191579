export const apiUrl = 'https://script.google.com/macros/s/AKfycbyZ1k8E7__EU0-Yg9rtzM4EW1aFEzF4X1KnaveO9W2RV41NJ2BpHLCKfeIcej5Xt_st8A/exec';

export default class Api {
  static getSheet(params) {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams(params);
      const urlWithParams = `${apiUrl}?${urlParams}`;
      return fetch(urlWithParams, {
        redirect: 'follow',
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain;charset=utf-8'
        }
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static postSheet(body) {
    return new Promise((resolve, reject) => {
      return fetch(
        apiUrl,
        {
          redirect: 'follow',
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'text/plain;charset=utf-8'
          }
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getSetting(params) {
    return new Promise((resolve, reject) => {
      const newParams = {
        ...params,
        action: 'settings'
      };
      const urlParams = new URLSearchParams(params);
      const urlWithParams = `${apiUrl}?${urlParams}`;
      return fetch(urlWithParams, {
        redirect: 'follow',
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain;charset=utf-8'
        }
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
