import { isEmpty } from 'lodash';

export function UseURLSearchParams({ history, params }) {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    if (params?.[key] && !['', 'ALL'].includes(params?.[key])) {
      searchParams.append(key, params?.[key]);
    }
  }

  return history.push({
    ...history.location,
    search: searchParams.toString() ? `?${searchParams.toString()}` : ''
  });
}
