import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FlexTable from '../../../components/FlexTable';
import { UseURLSearchParams } from '../../../hooks/useURLSearchParams';
import { getSheet } from '../../../store/modules/Common/actions';
import { getQueryParam, isRegexNumber } from '../../../utils/common.util';
import TableAction from '../../common/TableAction';
import EditModal from './EditModal';

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();

  const secretKey = useSelector((state) => state.common.secretKey);

  const [params, setParams] = useState({
    page: Number(getQueryParam('page', '1')),
    limit: Number(getQueryParam('limit', '10')),
    open: getQueryParam('open', undefined)
  });

  const fetcher = useCallback(() => {
    if (!secretKey) {
      return;
    }
    dispatch(getSheet({ ...params, secret: secretKey })).then((res) => {
      if (
        params.open !== undefined &&
        isRegexNumber(params.open) &&
        res?.payload?.data?.data?.length > 0 &&
        Number(params.open) < res?.payload?.data?.data?.length
      ) {
        const resData = res?.payload?.data?.data[Number(params.open)] ?? {};
        const { id, values } = resData;
        const tmp = {};
        values.forEach((value, index) => {
          tmp[index] = value;
        });

        if (!isEmpty(tmp)) {
          setSelected({
            id,
            ...tmp
          });
          setVisible(true);
        }
      }
    });
    UseURLSearchParams({ history, params });
  }, [dispatch, params, secretKey]);

  useEffect(() => {
    fetcher();
  }, [fetcher, secretKey]);

  const [selected, setSelected] = React.useState({});

  const columns = [
    {
      title: 'STT',
      dataIndex: '0',
      key: '0',
      width: 20
    },
    {
      title: 'Name',
      dataIndex: '1',
      key: '1',
      width: 100
    },
    {
      title: 'Phone',
      dataIndex: '2',
      key: '2',
      width: 100
    },
    {
      title: 'Email',
      dataIndex: '3',
      key: '3',
      width: 100
    },
    {
      title: 'Called',
      dataIndex: 'qa',
      key: 'qa',
      width: 200,
      render: (_, item) => {
        const values = Object.values(item).slice(-4, -1);
        let called = false;
        values.forEach((value) => {
          if (value) {
            called = true;
          }
        });

        return called ? 'Called' : '';
      }
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      width: 200,
      fixed: 'right',
      render: (_, item) => (
        <TableAction
          item={item}
          onEdit={() => {
            setSelected(item);
            setVisible(true);
          }}
        />
      )
    }
  ];

  const requesting = useSelector((state) => state.common.sheetData.requesting);
  const items = useSelector((state) => state.common.sheetData.result?.data) ?? [];
  const totalItems = useSelector((state) => state.common.sheetData.result?.totalRows);

  const mapDataToValues = (data) => {
    return data?.map((item, index) => {
      const { id, values } = item;
      const tmp = {};
      values.forEach((value, index) => {
        tmp[index] = value;
      });

      return {
        id,
        ...tmp
      };
    });
  };

  const handleOnChangeTable = useCallback(
    (pagination, filters, sorter) => {
      setParams({
        ...params,
        page: pagination.current,
        limit: pagination.pageSize
      });
      UseURLSearchParams({ history, params });
    },
    [params]
  );

  const [visible, setVisible] = React.useState(false);

  const onPrev = useCallback(() => {
    const data = mapDataToValues(items);
    const index = data?.findIndex((it) => it?.id === selected?.id);
    if (index === 0) {
      if (params.page === 1) {
        return;
      }
      const newParams = {
        ...params,
        page: params.page - 1,
        open: params.limit - 1
      };
      setParams(newParams);
      setVisible(false);
      return;
    }
    setVisible(false);
    setTimeout(() => {
      setSelected(data[index - 1]);
      setVisible(true);
    }, 100);
  }, [items, params, selected]);

  const onNext = useCallback(() => {
    const data = mapDataToValues(items);
    const index = data?.findIndex((it) => it?.id === selected?.id);
    if (index === data.length - 1) {
      if (params.page * params.limit >= totalItems) {
        return;
      }
      const newParams = {
        ...params,
        page: params.page + 1,
        open: 0
      };
      setParams(newParams);
      setVisible(false);
      return;
    }
    setVisible(false);
    setTimeout(() => {
      setSelected(data[index + 1]);
      setVisible(true);
    }, 100);
  }, [items, params, selected]);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FlexTable
            loading={requesting || !secretKey}
            requesting={requesting || !secretKey}
            onChangeTable={handleOnChangeTable}
            data={mapDataToValues(items)}
            columns={columns}
            totalItems={totalItems}
            title="Survey"
            params={params}
            pagination={{
              showTotal: (total) => <strong>{`Total ${total} items`}</strong>,
              showQuickJumper: true
            }}
            size="small"
            keyFilterColumn="survey"
          />
        </Col>
      </Row>
      {visible && (
        <EditModal
          visible={visible}
          onCancel={() => setVisible(false)}
          initialValues={selected}
          onRefresh={fetcher}
          showPrevNext={{
            pos: mapDataToValues(items)?.findIndex((it) => it?.id === selected?.id),
            total: totalItems,
            currentPage: params.page,
            limit: params.limit,
            onPrev,
            onNext
          }}
        />
      )}
    </div>
  );
}
