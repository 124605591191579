import { AutoComplete, Form, Input } from 'antd';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { Component } from 'react';

import styles from './FieldValidation.module.scss';

const { Option } = AutoComplete;
const { TextArea } = Input;

class FieldValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options
    };
  }

  onChange = (e) => {
    const { form, isUpperCase } = this.props;
    const { handleChange } = form;
    if (isUpperCase && e.target.value) {
      const value = (e.target.value || '').toString().toUpperCase();

      e.target.value = value;
    }
    handleChange(e);
  };

  onSearch = (searchText) => {
    if (!searchText) {
      return this.setState({ options: [] });
    }
    const regex = new RegExp(searchText, 'i');
    this.setState({
      options: !searchText ? [] : this.props.options.filter((item) => regex.test(item))
    });
  };

  onSelect = (data) => {
    const { form, field, isUpperCase } = this.props;
    const { setFieldValue } = form;
    if (!data) {
      data = '';
    }
    data = data.toString();

    setFieldValue(field.name, isUpperCase ? data.toUpperCase() : data);
  };

  onChangeAutoCompete = (data) => {
    const { form, field, isUpperCase } = this.props;
    const { setFieldValue } = form;
    if (!data) {
      data = '';
    }
    data = data.toString();

    setFieldValue(field.name, isUpperCase ? data.toUpperCase() : data);
  };

  render() {
    const {
      type,
      id,
      label,
      field,
      placeholder,
      form,
      inputSize,
      containerClassName,
      textArea,
      formItemLayout,
      isDisableInput,
      onBlur,
      rows,
      isAutocomplete,
      isUpperCase,
      inputProps,
      required
    } = this.props;

    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';

    let value = (field.value === undefined || field.value === null ? '' : field.value).toString();

    if (isUpperCase) {
      value = (value || '').toUpperCase();
    }

    if (!value && type !== 'number') {
      value = '';
    }

    if (isAutocomplete) {
      const { options } = this.state;
      return (
        <Form.Item
          {...formItemLayout}
          required={required}
          id={id}
          label={label}
          className={containerClassName}
          validateStatus={validateStatus}
          help={fieldError}
        >
          <AutoComplete
            value={value}
            onSearch={this.onSearch}
            onChange={this.onChangeAutoCompete}
            onSelect={this.onSelect}
            placeholder={placeholder}
          >
            {options &&
              options.map((text) => (
                <Option key={text} value={text}>
                  {text}
                </Option>
              ))}
          </AutoComplete>
        </Form.Item>
      );
    }

    if (textArea) {
      return (
        <Form.Item
          {...formItemLayout}
          required={required}
          id={id}
          label={label}
          className={containerClassName}
          validateStatus={validateStatus}
          help={fieldError}
        >
          <TextArea
            style={{ backgroundColor: isDisableInput ? '#fafafa' : 'unset' }}
            name={field.name}
            value={value}
            type={type || 'text'}
            onChange={this.onChange}
            size={inputSize || 'default'}
            placeholder={placeholder || ''}
            disabled={isDisableInput}
            rows={4}
          />
        </Form.Item>
      );
    }

    const inputPrefix = {};
    if (type === 'currency') {
      inputPrefix.prefix = '$';
      inputPrefix.suffix = 'USD';
    }

    if (type === 'date') {
      value = value ? dayjs(value).format('YYYY-MM-DD') : '';
    }

    const InputComponent = type === 'password' ? Input.Password : Input;

    return (
      <Form.Item
        {...formItemLayout}
        required={required}
        id={id}
        label={label}
        className={containerClassName}
        validateStatus={validateStatus}
        help={fieldError}
      >
        <InputComponent
          className={styles.passwordInput}
          style={{ backgroundColor: isDisableInput ? '#fafafa' : 'unset' }}
          onBlur={onBlur}
          name={field.name}
          value={value}
          type={type === 'currency' || type === 'number' ? 'text' : type || 'text'}
          onChange={this.onChange}
          size={inputSize || 'small'}
          placeholder={placeholder || ''}
          disabled={isDisableInput}
          {...inputPrefix}
          {...inputProps}
        />
      </Form.Item>
    );
  }
}

FieldValidation.defaultProps = {
  rows: 4
};

export default FieldValidation;
