import { handleActions } from 'redux-actions';

const initialState = {
  sheetData: {
    requesting: false,
    result: {},
    error: null
  },
  sheetDataPost: {
    requesting: false,
    result: [],
    error: null
  },
  setting: {
    requesting: false,
    result: [],
    error: null
  }
};

export const Oms = handleActions(
  {
    GET_OMS_SHEET_REQUEST: (state) => ({
      ...state,
      sheetData: {
        ...state.sheetData,
        requesting: true
      }
    }),
    GET_OMS_SHEET_SUCCESS: (state, { payload }) => ({
      ...state,
      sheetData: {
        ...state.sheetData,
        requesting: false,
        result: payload.data,
        error: null
      }
    }),
    GET_OMS_SHEET_FAIL: (state, { payload }) => ({
      ...state,
      sheetData: {
        ...state.sheetData,
        requesting: false,
        error: payload.error
      }
    }),
    POST_OMS_SHEET_REQUEST: (state) => ({
      ...state,
      sheetDataPost: {
        ...state.sheetDataPost,
        requesting: true
      }
    }),
    POST_OMS_SHEET_SUCCESS: (state, { payload }) => ({
      ...state,
      sheetDataPost: {
        ...state.sheetDataPost,
        requesting: false,
        result: payload.data,
        error: null
      }
    }),
    POST_OMS_SHEET_FAIL: (state, { payload }) => ({
      ...state,
      sheetDataPost: {
        ...state.sheetDataPost,
        requesting: false,
        error: payload.error
      }
    }),
    GET_OMS_SETTING_REQUEST: (state) => ({
      ...state,
      setting: {
        ...state.setting,
        requesting: true
      }
    }),
    GET_OMS_SETTING_SUCCESS: (state, { payload }) => ({
      ...state,
      setting: {
        ...state.setting,
        requesting: false,
        result: payload.data,
        error: null
      }
    }),
    GET_OMS_SETTING_FAIL: (state, { payload }) => ({
      ...state,
      setting: {
        ...state.setting,
        requesting: false,
        error: payload.error
      }
    }),
  },
  initialState
);

export default Oms;
