import { createAction, createActions } from 'redux-actions';

import Api from './api';

export const setSecretKey = createAction('SET_SECRET_KEY');

export const handleFilterExceptColumns = (key, exceptColumns) => (dispatch, getState) => {
  const { common } = getState();
  const { filterExceptColumns } = common;

  const newFilterExceptColumns = {
    ...filterExceptColumns,
    [key]: exceptColumns
  };

  dispatch(setFilterExceptColumns(newFilterExceptColumns));
}

export const setFilterExceptColumns = createAction('SET_FILTER_EXCEPT_COLUMNS');

const { getSheetRequest, getSheetSuccess, getSheetFail } = createActions({
  GET_SHEET_REQUEST: () => {},
  GET_SHEET_SUCCESS: (data) => ({ data }),
  GET_SHEET_FAIL: (error) => ({ error })
});

export const getSheet = (params) => (dispatch) => {
  dispatch(getSheetRequest());
  return Api.getSheet(params)
    .then((data) => {
      if (data?.errorMessage === 'Secret Wrong!') {
        dispatch(setSecretKey(''));
        throw new Error(data.error);
      }
      return dispatch(getSheetSuccess(data));
    })
    .catch((error) => {
      return dispatch(getSheetFail(error));
    });
};

const { postSheetRequest, postSheetSuccess, postSheetFail } = createActions({
  POST_SHEET_REQUEST: () => {},
  POST_SHEET_SUCCESS: (data) => ({ data }),
  POST_SHEET_FAIL: (error) => ({ error })
});

export const postSheet = (body) => (dispatch) => {
  dispatch(postSheetRequest());
  return Api.postSheet(body)
    .then((data) => {
      if (data?.errorMessage === 'Secret Wrong!') {
        dispatch(setSecretKey(''));
        throw new Error(data.error);
      }
      return dispatch(postSheetSuccess(data));
    })
    .catch((error) => {
      return dispatch(postSheetFail(error));
    });
};

const { getSettingRequest, getSettingSuccess, getSettingFail } = createActions({
  GET_SETTING_REQUEST: () => {},
  GET_SETTING_SUCCESS: (data) => ({ data }),
  GET_SETTING_FAIL: (error) => ({ error })
});

export const getSetting = (params) => (dispatch) => {
  dispatch(getSettingRequest());
  return Api.getSetting(params)
    .then((data) => {
      return dispatch(getSettingSuccess(data));
    })
    .catch((error) => {
      return dispatch(getSettingFail(error));
    });
};
