import { Button, Form, Radio, Space } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class RadioValidation extends Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    form: PropTypes.object,
    field: PropTypes.object,
    options: PropTypes.array,
    labelName: PropTypes.string,
    valueName: PropTypes.string,
    containerClassName: PropTypes.string,
    isDisable: PropTypes.bool
  };

  static defaultProps = {
    options: []
  };

  componentDidMount() {
    const { options, field, form } = this.props;
    const { setFieldValue } = form;

    for (let i = 0; i < options.length; i++) {
      if (options[i]?.default) {
        setFieldValue(field.name, options[i].id);
      }
    }
  }

  onChange = (e) => {
    const { form, field, onChange } = this.props;
    const { setFieldValue } = form;

    setFieldValue(field.name, e.target.value);

    if (onChange && typeof onChange === 'function') {
      onChange(e.target.value, { form });
    }
  };

  onClear() {
    const { form, field, onChange } = this.props;
    const { setFieldValue } = form;

    setFieldValue(field.name, '');

    if (onChange && typeof onChange === 'function') {
      onChange('', { form });
    }
  }

  render() {
    const {
      id,
      isButton,
      form,
      label,
      field,
      options,
      labelName,
      valueName,
      isDisable,
      containerClassName,
      required,
      direction
    } = this.props;

    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';
    const spaceDirection = direction;

    const RadioItem = isButton ? Radio.Button : Radio;

    return (
      <Form.Item
        required={required}
        id={id}
        label={label}
        className={containerClassName}
        validateStatus={validateStatus}
        help={fieldError}
      >
        <Radio.Group
          disabled={isDisable}
          onChange={this.onChange}
          value={field.value}
          style={{ marginTop: 10 }}
        >
          <Space direction={spaceDirection} wrap={spaceDirection !== 'vertical'}>
            {options.map((item, index) =>
              typeof item === 'string' ? (
                <RadioItem key={index} value={item}>
                  {item}
                </RadioItem>
              ) : (
                <RadioItem key={index} value={item[`${valueName}`]}>
                  {item[`${labelName}`] || ''}
                </RadioItem>
              )
            )}
            <Button type="link" style={{ fontStyle: 'italic', color: 'darkgray' }} onClick={() => this.onClear()}>Clear</Button>
          </Space>
        </Radio.Group>
      </Form.Item>
    );
  }
}

export default RadioValidation;
