import { createActions } from 'redux-actions';

import { setSecretKey } from '../Common/actions';
import Api from './api';

const { getOmsSheetRequest, getOmsSheetSuccess, getOmsSheetFail } = createActions({
  GET_OMS_SHEET_REQUEST: () => {},
  GET_OMS_SHEET_SUCCESS: (data) => ({ data }),
  GET_OMS_SHEET_FAIL: (error) => ({ error })
});

export const getOmsSheet = (params) => (dispatch) => {
  dispatch(getOmsSheetRequest());
  return Api.getOmsSheet(params)
    .then((data) => {
      if (data?.errorMessage === 'Secret Wrong!') {
        dispatch(setSecretKey(''));
        throw new Error(data.error);
      }
      return dispatch(getOmsSheetSuccess(data));
    })
    .catch((error) => {
      return dispatch(getOmsSheetFail(error));
    });
};

const { postOmsSheetRequest, postOmsSheetSuccess, postOmsSheetFail } = createActions({
  POST_OMS_SHEET_REQUEST: () => {},
  POST_OMS_SHEET_SUCCESS: (data) => ({ data }),
  POST_OMS_SHEET_FAIL: (error) => ({ error })
});

export const postOmsSheet = (body) => (dispatch) => {
  dispatch(postOmsSheetRequest());
  return Api.postOmsSheet(body)
    .then((data) => {
      if (data?.errorMessage === 'Secret Wrong!') {
        dispatch(setSecretKey(''));
        throw new Error(data.error);
      }
      return dispatch(postOmsSheetSuccess(data));
    })
    .catch((error) => {
      return dispatch(postOmsSheetFail(error));
    });
};

const { getOmsSettingRequest, getOmsSettingSuccess, getOmsSettingFail } = createActions({
  GET_OMS_SETTING_REQUEST: () => {},
  GET_OMS_SETTING_SUCCESS: (data) => ({ data }),
  GET_OMS_SETTING_FAIL: (error) => ({ error })
});

export const getOmsSetting = () => (dispatch) => {
  dispatch(getOmsSettingRequest());
  return Api.getOmsSetting()
    .then((data) => {
      return dispatch(getOmsSettingSuccess(data));
    })
    .catch((error) => {
      return dispatch(getOmsSettingFail(error));
    });
};
