import { Layout, Menu, theme } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSecretKey } from '../../store/modules/Common/actions';
import { menuOptions } from './menu.options';

const { Content, Sider } = Layout;

const MainLayout = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken();

  const dispatch = useDispatch();
  const secretKey = useSelector((state) => state.common.secretKey);
  useEffect(() => {
    if (!secretKey) {
      // eslint-disable-next-line no-alert
      const key = prompt('Please enter your secret key');
      if (key) {
        dispatch(setSecretKey(key));
      }
    }
  }, [dispatch, secretKey]);

  // get url path
  const path = window.location.pathname;

  const defaultSelectedKeys = [path.slice(1) || 'home'];

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log('onBreakpoint: ', broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log('onCollapse', collapsed, type);
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={defaultSelectedKeys} items={menuOptions} />
      </Sider>
      <Layout style={{ height: '100vh' }}>
        <Content
          style={{
            height: '100%'
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
