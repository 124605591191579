import { HomeOutlined, SolutionOutlined } from '@ant-design/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const menuOptions = [
  {
    key: "home",
    label: (
      <NavLink to="/">
        {React.createElement(HomeOutlined)}
        {` Canopi camera`}
      </NavLink>
    ),
  },
  {
    key: "oms",
    label: (
      <NavLink to="/oms">
        {React.createElement(SolutionOutlined)}
        {` OMS`}
      </NavLink>
    ),
  },
];