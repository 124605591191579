import axios from 'axios';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { times } from 'lodash';

const getStringFromUrl = (str) => decodeURIComponent(str).replace(/\+/g, ' ');

export const getQueryParam = (name, defaultData = null) => {
  const q = window.location.search.match(new RegExp(`[?&]${name}=([^&#]*)`));
  const result = q ? getStringFromUrl(q[1]) : defaultData;
  if (result && result.includes(',')) {
    return result.split(',');
  }

  return result;
};

export const createDefaultUploadFileFromUrl = (url) => {
  if (!url) {
    return null;
  }
  if (url?.url) {
    url = url.url;
  }
  return {
    uid: Date.now() + url,
    url,
    status: 'done',
    name: (url || '').split('/').pop() || ''
  };
};

export const formatCurrency = (value, currency = 'USD') => {
  const newValue = normalizeValue(value);

  const fixedAmount = currency === 'USD' ? 2 : 0;
  const thousandsSeparator = currency === 'USD' ? ',' : '.';

  if (!value) {
    return currency === 'USD' ? '$0.00' : '0đ';
  }

  const formatter = separateThousands(newValue.toFixed(fixedAmount), thousandsSeparator);
  return currency === 'USD' ? `$${formatter}` : `${formatter}đ`;
};

function normalizeValue(value) {
  if (typeof value === 'string') {
    return parseFloat(value);
  }

  return value;
}

export function separateThousands(x, s) {
  const arrSplitValue = x.split('.');
  if (arrSplitValue && arrSplitValue.length < 2) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
  }
  const firstNumber = arrSplitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, s);
  return `${firstNumber}.${arrSplitValue[1]}`;
}

export const formatInputCurrency = (value, currency = 'USD') => {
  // Format value as number with two decimal places
  let formattedValue = Number(value).toFixed(2);

  // If currency is USD, format with commas
  if (currency === 'USD') {
    formattedValue = formattedValue.replace(/\d(?=(\d{3})+\.)/g, '$&,');
  } else if (currency === 'VND') {
    formattedValue = Math.floor(Number(formattedValue))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return formattedValue;
};

export const parseInputCurrency = (value, currency = 'USD') => {
  const regex = currency === 'USD' ? /,/g : /\./g;
  return Number(value.replace(regex, '').replace('..', '.'));
};

const fixedFloat = (input, decimals) => {
  const arr = input.toString().split('.');
  if (arr.length === 1) {
    return decimals === 0 ? input : [input, '.', times(decimals, () => '0').join('')].join('');
  }
  const int = arr[0];
  const max = arr[1].length;
  const dec = arr[1].substr(0, decimals > max ? max : decimals);
  return decimals === 0
    ? int
    : [
        int,
        '.',
        dec.length >= decimals ? dec : dec + times(decimals - dec.length, () => '0').join('')
      ].join('');
};

export const getErrorMessage = (error) => {
  let message =
    error?.data?.message ?? error?.data?.message?.[0] ?? error?.data?.error ?? error?.message;
  console.log('message: ', message);
  if (typeof message === 'string') {
    return message;
  }
  if (error?.data?.statusCode === 422 && Array.isArray(message)) {
    if (Array.isArray(message)) {
      message = `Error: ${message
        ?.map((it) => it?.property)
        ?.filter(Boolean)
        ?.join(', ')}`;
    } else {
      message = error?.data?.error;
    }
    return message;
  }
  return null;
};

export const sendTelegramMessage = (messages) => {
  return new Promise((resolve) => {
    const token = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const body = {
      // eslint-disable-next-line camelcase
      chat_id: process.env.REACT_APP_TELEGRAM_CHAT_ID,
      text: messages,
      // disable_notification: false,
      // eslint-disable-next-line camelcase
      parse_mode: 'MarkdownV2'
    };
    return axios
      .request({
        url: `https://api.telegram.org/bot${token}/sendMessage`,
        method: 'POST',
        data: body,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      .then(resolve)
      .catch((e) => console.error(e));
  });
};

export const stringToColor = (str) => {
  if (!str) {
    return '#21244d';
  }
  const hash = CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex);

  // Extract 6 characters from the hash string for RGB color components
  const r = Math.round(parseInt(hash.slice(0, 2), 16) / 1.8);
  const g = parseInt(hash.slice(2, 4), 16);
  const b = parseInt(hash.slice(4, 6), 16);

  // Construct CSS hex color code from RGB components
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
};

export const validateEmail = (email) => {
  const regexCheckEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/i;
  return regexCheckEmail.test(String(email).toLowerCase());
};

export function copyToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

export const removeEmptyValueByKeys = (object = {}, keys = []) => {
  for (const key of keys) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      if (!object[key] || object[key] === '' || object[key] === null) {
        delete object[key];
      }
    }
  }
};

export const removeEmptyValues = (object = {}) => {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      if (!object[key] || object[key] === '' || object[key] === null || object[key] === 'ALL') {
        delete object[key];
      }
    }
  }
};

export const isRegexNumber = (value) => {
  if (typeof value === 'number') {
    return true;
  }
  if (typeof value !== 'string') {
    return false;
  }
  const regex = /^\d+$/;
  return regex.test(value);
};

const antdTagColors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple'
];

export const getTagColorByString = (string) => {
  const hash = CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
  const index = parseInt(hash.slice(0, 2), 16) % antdTagColors.length;
  return antdTagColors[index];
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const isImageLink = (url) => {
  const regex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
  return regex.test(url);
};

export const isArrayString = (value) => {
  let somethingIsNotString = false;
  if (Array.isArray(value)) {
    somethingIsNotString = value.every((it) => it instanceof String);
    if (!somethingIsNotString && value.length > 0) {
      console.log('string[]!');
    }
  } else {
    somethingIsNotString = true;
  }
  return !somethingIsNotString;
};

export const getFirstDateAndLastDateOnThePanel = (val) => {
  const date = val instanceof dayjs ? val : dayjs(val);
  let firstDate = date.startOf('month');
  let lastDate = date.endOf('month');

  const firstDateDay = firstDate.day();
  firstDate = firstDate.subtract(firstDateDay, 'days');
  lastDate = lastDate.add(42 - Number(lastDate.format('DD')) - firstDateDay, 'days');

  return {
    firstDate,
    lastDate
  };
};

export function ellipseName(address = '', width = 6) {
  if (!address) {
    return '';
  }
  return `${address.slice(0, width)}...`;
}

export const getTimeFormat = (val) => val?.slice(0, 5);

export const addMissingDateToCalendar = (
  startDate,
  endDate,
  rootData,
  fieldDateReturn = 'date'
) => {
  if (!(startDate instanceof dayjs)) {
    startDate = dayjs(startDate);
  }

  if (!(endDate instanceof dayjs)) {
    endDate = dayjs(endDate);
  }

  if (startDate.isAfter(endDate)) {
    return [];
  }

  const result = [];

  for (let date = startDate; date.isBefore(endDate); date = date.add(1, 'day')) {
    const data = rootData.find((it) => {
      const dateReturn = dayjs(it[fieldDateReturn]);
      return dateReturn.isSame(date, 'day');
    });

    result.push({
      [fieldDateReturn]: date,
      ...data
    });
  }

  return result;
};

export const removeAscent = (str) => {
  if (!str) return '';
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
};

export const formatDateApi = (value, format) => {
  if (!value) {
    return null;
  }
  if (value instanceof dayjs) {
    return value.format(format);
  }
  return dayjs(value).format(format);
};

export const formatDateToApi = (value, hasTime = true) => {
  if (!value) {
    return null;
  }

  if (!(value instanceof dayjs)) {
    value = dayjs(value);
  }
  if (!hasTime) {
    value = value.format('YYYY-MM-DD');
    return value;
  }

  return value.toISOString();
};

export const isFileNameImageType = (fileName) => {
  const ext = fileName?.split('.').pop();
  return ['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(ext || '');
};

export const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const defaultFormItemLayout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
};

export const replaceFirstZeroByCountryCode = (phoneNumber, countryCode = '+84') => {
  if (phoneNumber?.startsWith('0')) {
    return phoneNumber.replace('0', countryCode);
  }
  return phoneNumber;
};

export const jsonParse = (data) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const reactLocalStorage = {
  set(key, value) {
    localStorage[key] = value;
    return localStorage[key];
  },

  get(key) {
    return localStorage[key];
  },

  setObject(key, value) {
    localStorage[key] = JSON.stringify(value);
    return localStorage[key];
  },

  getObject(key, defaultValue) {
    return jsonParse(localStorage[key] || JSON.stringify(defaultValue));
  },

  remove(key) {
    return localStorage.removeItem(key);
  },

  clear() {
    return localStorage.clear();
  }
};

export const sessionStorageUtil = {
  set(key, value) {
    sessionStorage[key] = value;
    return sessionStorage[key];
  },

  get(key, defaultValue) {
    return sessionStorage[key] || defaultValue;
  },

  setObject(key, value) {
    sessionStorage[key] = JSON.stringify(value);
    return sessionStorage[key];
  },

  getObject(key, defaultValue) {
    return jsonParse(sessionStorage[key] || JSON.stringify(defaultValue));
  },

  remove(key) {
    return sessionStorage.removeItem(key);
  },

  clear() {
    return sessionStorage.clear();
  }
};

/**
 * Check times overlap
 *
 * Example:
 * const times = [
 *   ["03:00", "04:00"],
 *   ["02:00", "07:00"],
 *   ["12:00", "15:00"]
 * ]
 */
export const checkTimesOverlap = (times) => {
  if (
    !(
      Array.isArray(times) &&
      times.length > 1 &&
      times.every((t) => Array.isArray(t) && t.length === 2)
    )
  ) {
    return false;
  }

  times.sort((time1, time2) => time1[0].localeCompare(time2[0]));

  for (let i = 0; i < times.length - 1; i++) {
    const currentEndTime = times[i][1];
    const nextStartTime = times[i + 1][0];

    if (currentEndTime > nextStartTime) {
      return true;
    }
  }

  return false;
};

export const isValidVnPhone = (phone) =>
  /(\+84|0)(3[2-9]|5[689]|7[06789]|8[1-9]|9[1-46-9])[0-9]{7}\b/g.test(phone);

export function hashString(text) {
  try {
    // Sử dụng SHA-256 để hash
    return CryptoJS.SHA256(text).toString();
  } catch (error) {
    console.error('Error hashing string:', error);
    return null;
  }
}

export function parseTextJoinForSheet(input) {
  if (typeof input !== 'string' || !input.startsWith('=')) {
    return input;
  }

  const textJoinPattern = /=TEXTJOIN\(([^,]+), ([^,]+), (.+)\)/;
  const matches = input.match(textJoinPattern);

  if (matches) {
    const optionsStr = matches[3]; // Các tham số ô
    return optionsStr
      .split(',')
      .map((ref) => ref.trim())
      .slice(1); // Trả về mảng chứa các ô
  }

  return input;
}
