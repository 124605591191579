import styled from 'styled-components';

export const FloatContainer = styled.div`
  .float-label {
    position: relative;
  }

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 9px;
    transition: 0.2s ease all;
    color: #bebebe;
  }

  .as-placeholder {
    color: #bebebe;
  }

  .as-label {
    top: -8px;
    font-size: 12px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
    z-index: 1;
  }
`;

export const BaseContainer = styled.div``;
